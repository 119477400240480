import {Slot} from '@radix-ui/react-slot';
import {cva, type VariantProps} from 'class-variance-authority';
import * as React from 'react';
import {cn} from '~/utils';

const buttonVariants = cva(
  'items-center flex flex-row justify-center font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-primary text-card hover:bg-primary/90',
        destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline: 'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        'destructive-outline':
          'border border-destructive text-destructive hover:bg-destructive hover:text-destructive-foreground',
        secondary: 'bg-secondary text-card hover:bg-secondary/90',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        translucent: 'hover:bg-foreground/10 text-accent-foreground',
      },
      size: {
        default: 'h-10 py-2.5 text-sm rounded-md px-4 py-2 gap-4',
        flex: 'py-2.5 text-sm rounded-md px-4 py-2 gap-4',
        sm: 'h-9 py-2 text-sm rounded-md px-3 gap-3',
        lg: 'h-14 py-3 rounded-md px-10 gap-10 text-lg',
        icon: 'h-10 py-2.5 w-10 rounded-full',
        'icon-lg': 'h-[60px] py-3.5 w-[60px] rounded-full',
        square: 'h-10 py-2.5 w-10 rounded-md',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({className, variant, size, asChild = false, ...props}, ref) => {
    const Comp = asChild ? Slot : 'button';
    return <Comp className={cn(buttonVariants({variant, size, className}))} ref={ref} {...props} />;
  },
);
Button.displayName = 'Button';

export {Button, buttonVariants};
